/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable no-nested-ternary */
import cn from 'classnames';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { MdPlayCircleFilled } from 'react-icons/md';
import { FaYoutube } from 'react-icons/fa';
import Image from '@/components/atoms/Image';
import styles from './thumbnailIndicator.module.scss';
import { Icon } from '@/components/atoms';
import useDeviceType from '@/hooks/useDeviceType';
import { GeneralModal } from '@/components/molecules';
import validateVideoFile from '@/utils/validateVideoFile';

const YTVideo = ({ link, setShowYTVideo, isDesktop, responsive }) => {
  return (
    <button
      key="imagen youtubeVideo"
      onClick={() => {
        setShowYTVideo(link);
      }}
      className={styles.dot}
      aria-label="abrir video de review"
    >
      {isDesktop && responsive && (
        <FaYoutube
          color="#FF0000"
          fontSize={46}
        />
      )}
    </button>
  );
};
YTVideo.propTypes = {
  link: PropTypes.string.isRequired,
  setShowYTVideo: PropTypes.func.isRequired,
  isDesktop: PropTypes.bool.isRequired,
  responsive: PropTypes.bool.isRequired
};

const ThumbnailIndicator = ({
  images,
  indexImage,
  goToImage,
  showZoomModal,
  className,
  responsive,
  embeddeds
}) => {
  const { isDesktop } = useDeviceType();
  const [showYTVideo, setShowYTVideo] = useState('');
  const dotsClasses = cn({
    [styles.dots]: true,
    [styles.zoomModal]: showZoomModal,
    [className]: !!className
  });
  return (
    <div className={dotsClasses}>
      {isDesktop && embeddeds.youtube?.[0] && (
        <YTVideo
          link={embeddeds.youtube?.[0]}
          setShowYTVideo={setShowYTVideo}
          isDesktop={isDesktop}
          responsive={responsive}
        />
      )}

      {[
        ...(embeddeds.render?.map(() => '/assets/360.jpg') || []),
        ...images
      ]?.map((image, i) => (
        <button
          key={`imagen ${image.altText || i}`}
          onClick={() => {
            goToImage(i);
          }}
          className={styles.dot}
          aria-label="Cambiar de imagen"
        >
          {isDesktop && responsive ? (
            validateVideoFile(image.thumbnail?.ext) ? (
              <MdPlayCircleFilled size={30} />
            ) : (
              <Image
                alt={`imagen ${image.altText || i}`}
                className={`${
                  indexImage === i ? styles.selected : styles.noSelected
                } ${showZoomModal && styles.zoomModal}`}
                width={46}
                height={46}
                src={image.thumbnail?.url || image}
              />
            )
          ) : (
            <Icon
              type="simpleDot"
              width=".7rem"
              color={
                indexImage === i
                  ? 'var(--color-primary)'
                  : 'var(--color-grey-light)'
              }
            />
          )}
        </button>
      ))}
      {showYTVideo !== '' && (
        <GeneralModal
          onClose={() => setShowYTVideo('')}
          closeColor="white"
          modalStyles={{
            backgroundColor: 'white',
            width: '600px',
            height: '43%',
            maxHeight: '50.2vw',
            maxWidth: '90%',
            padding: 0
          }}
        >
          <iframe
            width="100%"
            height="100%"
            style={{ borderRadius: '30px' }}
            frameBorder={0}
            src={showYTVideo}
            title="Titulo"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          />
        </GeneralModal>
      )}
    </div>
  );
};

ThumbnailIndicator.propTypes = {
  images: PropTypes.array.isRequired,
  indexImage: PropTypes.number.isRequired,
  goToImage: PropTypes.func.isRequired,
  showZoomModal: PropTypes.bool.isRequired,
  className: PropTypes.string,
  responsive: PropTypes.bool,
  embeddeds: PropTypes.shape({
    youtube: PropTypes.array.isRequired,
    render: PropTypes.array.isRequired
  })
};
ThumbnailIndicator.defaultProps = {
  className: '',
  responsive: true,
  embeddeds: { youtube: [], render: [] }
};

export default ThumbnailIndicator;
